import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules"
import 'swiper/css';
export const SwiperComp = (props) => {
  return (
    <Swiper
    modules={[Autoplay]}
      className="mobile-mySwiper"
      autoplay={{
        delay: 0,
        disableOnInteraction: false, // 不设置只会轮播一次
        pauseOnMouseEnter: false, // 鼠标移入暂停
        reverseDirection:props.direction
      }}
      speed={7000} // 是速度
      loop={true} // 无限循环
      slidesPerView={2}
      spaceBetween={5}
      observer={true}
      observeParents={true}
      freeMode={true}
      centeredSlides={true}
      initialSlide={4}  
     
    >
      {props.imgList.map((item, index) => {
        return (
          <SwiperSlide className="mobile-slide " key={index}>
            <img src={require(`@/assets/mobile/sw0/${item}`)} alt="" ></img>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
