import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper/modules"
import 'swiper/css';
export const SwiperHome = (props) => {
  const numbers = Array.from({ length: 4 }, (_, i) => i);
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      className="mobile-mySwiper"
      autoplay={{
        delay: 0,
        disableOnInteraction: false, // 不设置只会轮播一次
        pauseOnMouseEnter: false, // 鼠标移入暂停
      }}
      speed={6000} // 是速度
      loop={true} // 无限循环
      slidesPerView={1}
      observer={true}
      observeParents={true}
      freeMode={true}
      centeredSlides={true}
      pagination={{
        clickable: true
      }} // 对应 Pagination

    >
      {numbers.map((number) => {
        return (
          <SwiperSlide className="mobile-slide" key={number + '-' + props.imgFile}>
            <img src={require(`@/assets/mobile/${props.imgFile}/sw${number}.png`)} alt="" ></img>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
