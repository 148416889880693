import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Pagination } from "swiper/modules"
import 'swiper/css';
export const SwiperTrain = (props) => {
  return (
    <Swiper
      modules={[Autoplay,Pagination]}
      className="mobile-mySwiper"
      autoplay={{
        delay: 0,
        disableOnInteraction: false, // 不设置只会轮播一次
        pauseOnMouseEnter: false, // 鼠标移入暂停
      }}
      speed={6000} // 是速度
      loop={true} // 无限循环
      slidesPerView={1}
      spaceBetween={20}
      observer={true}
      observeParents={true}
      freeMode={true}
      centeredSlides={true}
      pagination={{
        clickable: true
      }} // 对应 Pagination


    >

      <SwiperSlide className="mobile-slide " >
        <span className="mobile-teaching ">
          <h1>Teaching Courses</h1>
          <div className="mobile-contentTxt mobile-contentTxt1">
            <p className="mobile-title">
              Course 1. Blockchain and Dapp Development<br />
              <a>(with coding module)</a>
            </p>
            <p>
              Module 1: Blockchain Introduction<br />
              Lecture 1: Decentralizing the World, Diffusion of Web3 Innovation<br />
              Lecture 2: Blockchain 101<br />
              Lecture 3: Bridge Web2 and Web3, Metaverse and Middlewares
            </p>
            <p>
              Module 2: Smart Contract and Dapp design<br />
              Lecture 1: Smart Contract 101 – Build your first smart contract<br />
              Lecture 2: AI Driven Full Stack Development<br />
              Lecture 3: Fundamental Steps of Building a Dapp
            </p>
            <p>
              Module 3: Developing a Blockchain Game<br />
              Lecture 1: Blockchain Game Designer 1<br />
              Lecture 2: Blockchain Game Designer 2<br />
              Lecture 3: Blockchain Game Designer 3
            </p>
            <p>
              Module 4: Blockchain, Multimedia and Data Science<br />
              Lecture 1: BMDS Applications I: Where Blockchain Meets Interactive Multimedia<br />
              Lecture 2: Blockchain & AI Blockchain -empowered Privacy-Preserving Machine Learning and the Trusted Computing<br />
              Lecture 3: Master of Blockchain: Hands-on Experience for Blockchain
            </p>
            <p>Module 5: Group Project</p>
            <p className="title">
              Course 2. Web 3.0 Eco-system and Analytics <br />
              <a>(No-coding skills required)</a>
            </p>
            <p>
              Module 1: Blockchain Introduction<br />
              Lecture 1: Decentralizing the World, Diffusion of Web3 Innovation<br />
              Lecture 2: Blockchain 101<br />
              Lecture 3: Bridge Web2 and Web3, Metaverse and Middlewares
            </p>
            <p>
              Module 2: Blockchain and Metaverse<br />
              Lecture 1: From Blockchain to Metaverse<br />
              Lecture 2: Metaverse for Social Good: Thoughts from University Perspective<br />
              Lecture 3: Metaverse ecosystem and community construction
            </p>
            <p>
              Module 3: Crypto Art and NFT<br />
              Lecture 1: How Crypto Changes Art and What Creators Can Learn from it？<br />
              Lecture 2: Entering the Metaverse: Why NFTs are important?<br />
              Lecture 3: NFT and creator economy
            </p>
            <p>
              Module 4: Token Economy<br />
              Lecture 1: How Blockchains and Smart Contracts Revolutionize the Economy<br />
              Lecture 2: Opportunities of Defi and NFT in Metaverse<br />
              Lecture 3: GamingFi: Building a sustainable gaming economy
            </p>
            <p>
              Module 5: Blockchain game ecosystem<br />
              Lecture 1: How Blockchain Gaming is Taking the Crypto Ecosystem to the Next Level<br />
              Lecture 2: Start a Business in Blockchain
            </p>
            <p>
              Team Project: Analytics on a web3 project
            </p>
          </div>
        </span>
      </SwiperSlide>
      <SwiperSlide className="mobile-slide" >
        <span className="mobile-training ">
          <h1>Industrial Training</h1>
          <div className="mobile-contentTxt ">
            <p> Welcome to our Industrial Training Program, where we will explore cutting-edge technologies and business opportunities in the UAE. </p>
            <p>This comprehensive training program will cover a range of topics, including Blockchain innovation, Web 3.0, Cloud Computing, Big data, Artificial Intelligence, Data Analytics, FinTech, as well as insights into the UAE business environment and opportunities, including Free Zone policy.</p>
            <p>  Throughout this training program, participants will have the opportunity to gain practical skills and knowledge in these key areas, enabling them to stay ahead in today's rapidly evolving industrial landscape. Our expert trainers will provide in-depth insights and hands-on experience, equipping participants with the tools and expertise needed to thrive in the dynamic UAE business environment.</p>
            <p>   Join us as we delve into the exciting world of technology and business in the UAE, and embark on a journey of discovery and innovation.</p>
            <p>   Let's shape the future together!</p>
          </div>
        </span>
      </SwiperSlide>

    </Swiper>
  )
}
