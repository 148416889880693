import nav from '@/assets/mobile/nav.png';
import home_1 from '@/assets/mobile/home_1.png';
import people_1 from '@/assets/mobile/people_1.png';
import researcher from '@/assets/mobile/researcher.png';
import group from '@/assets/mobile/group.png';
import icon1 from '@/assets/mobile/icon1.png';
import icon2 from '@/assets/mobile/icon2.png';
import icon3 from '@/assets/mobile/icon3.png';
import icon4 from '@/assets/mobile/icon4.png';
import icon5 from '@/assets/mobile/icon5.png';
import email from '@/assets/mobile/email.png';
import $ from 'jquery';
import { SwiperTrain } from '@/components/mobile/swiper2';
import { SwiperHome } from '@/components/mobile/swiper1';
import { SwiperComp } from '@/components/mobile/swiper0';
import './phone.css';

function Mobile() {
  var isShowNavMobile = false;
  const swiperList1 = [
    'sw1.png',
    'sw2.png',
    'sw3.png',
    'sw4.png',
    'sw5.png',
  ]
  const swiperList2 = [
    'sw6.png',
    'sw7.png',
    'sw8.png',
    'sw9.png',
    'sw10.png',
  ]

  const scrollToAnchor = (anchorName) => {
    const scrollTop = document.documentElement.scrollTop;
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: 'start', behavior: 'instant' });
        $(".mobile-newNav").hide();
        isShowNavMobile = false
      }
    }
  }
  const handleNavMobile = () => {
    if (isShowNavMobile) {
      $(".mobile-newNav").hide();
      isShowNavMobile = false
    } else {
      $(".mobile-newNav").fadeIn();
      isShowNavMobile = true
    }

  }
  // const scientistsData = [
  //   {
  //     name: 'Wu Xiao',
  //     blurb: 'Industrial Co-PI  Vice Director of the CUHKSZ-WM Joint Metaverse Laboratory Founder of ChainIDE Co-founder of MatrixLabs Co-organizer of ETH Riyadh and ETH Shanghai'
  //   },
  //   {
  //     name: 'Dr.Julija Bainiaksina',
  //     blurb: 'PhD, University College London  Founder of Networky, UK Royal Academy of Engineering Enterprise Fellow'
  //   },
  //   {
  //     name: 'Dr.Anish Mohammed',
  //     blurb: 'PhD, Royal Holloway, University of London Chief Scientist at Panther Protocol'
  //   },
  //   {
  //     name: 'Dr.Abigail Casten Copiaco',
  //     blurb: 'University of Dubai, IEEE UAE'
  //   },
  //   {
  //     name: 'TonyTong',
  //     blurb: 'Co-Chairman of Hong Kong Blockchain Association Co-Founder of Edi.College. Adjunct Professor at International Technological University ITU, Silicon Valley, USA Co-Chairman of Belt & Road TechFin Association'
  //   },
  //   {
  //     name: 'Tess Hau',
  //     blurb: 'Stanford, Venture Capitalist Founder of Tess Ventures'
  //   },
  //   {
  //     name: 'Ramzi Dennaoui',
  //     blurb: 'Founder of UNBUNDL Co-founder of Web3 Lebanon'
  //   },
  //   {
  //     name: 'Yaodong Li',
  //     blurb: 'Co-founder of Dubai Society X'
  //   },
  // ]
  // const Scientists = () => {
  //   const numbers = Array.from({ length: 4 }, (_, i) => i);
  //   var elements = [];
  //   scientistsData.forEach((item, index) => {
  //     elements.push(<li key={index + '_Scientists'} className={"mobile-secientists_" + index}>
  //       <img src={require(`@/assets/mobile/group/group${index}.png`)} className="mobile-scientistImg"></img>
  //       <div className="mobile-peopleInfo"> {item.blurb} </div>
  //     </li>);
  //   })


  //   return elements;
  // }
  const partnerUrl = [
    { url: '', name: '' },
    { url: '', name: '' },
    { url: '', name: '' },
  ];
  const Partner = () => {
    var elements = [];
    partnerUrl.forEach((item, index) => {
      elements.push(<a key={index} href={item.url} className={"mobile-partner_" + index}>
        <img src={require(`@/assets/mobile/partner/partner${index}.png`)} className="mobile-partnerImg"></img>
      </a>);
    })

    return elements;
  }
  const supportUrl = [
    { url: '', name: '' },
    { url: '', name: '' },
    { url: '', name: '' },
    { url: '', name: '' },
    { url: '', name: '' },
    { url: '', name: '' },
    { url: '', name: '' },
    { url: '', name: '' },
  ];
  const Support = () => {
    var elements = [];
    supportUrl.forEach((item, index) => {
      elements.push(<a key={index} href={item.url} className={"support_" + index + " " + ''} >
        <img src={require(`@/assets/mobile/support/support${index}.png`)} className="mobile-supportImg"></img>
      </a>);
    })

    return elements;
  }
  return (
    <div className="Mobile" id="Mobile">
      <header className="mobile-navHeader">
        <img onClick={handleNavMobile} className="mobile-navBtn" src={nav} />
        <div className="mobile-newNav">
          <a onClick={() => scrollToAnchor('mobile-homeBox')} >Home</a>
          <a onClick={() => scrollToAnchor('mobile-newsEvents')}  >News & Events</a>
          <a onClick={() => scrollToAnchor('mobile-train-teach')} >Teaching and Training</a>
          <a onClick={() => scrollToAnchor('mobile-people')} >People</a>
          <a onClick={() => scrollToAnchor('mobile-partner')} >Partners</a>
          <a onClick={() => scrollToAnchor('mobile-footerBox')}>Contact Us</a>
        </div>
      </header>
      <div className="mobile-homeBox " id="mobile-homeBox">
        <div className="mobile-part-1 ">
          <h1 className="mobile-tit-1  sgFont">Blockchain Innovation and Analytics Lab</h1>
          <span className="mobile-txt-1 wsFont fontSize12">The Blockchain Innovation and Analytics Lab aims to be a hub of excellence in research, training and development, focusing on leveraging technology for sustainable business solutions. The lab, strategically embedded in the research clusters of both Future of Business and Sustainability, school of business, aims to be a pioneering platform for cutting-edge research, development, and education in the rapidly evolving field of business innovation.</span>
          <img src={home_1} className="mobile-home_1" alt="icon" />
        </div>
        <div className="mobile-part-2 ">
          <h1 className="mobile-lineImg mobile-sameTit ">Objectives</h1>
          <span className="mobile-txt-2 wsFont fontSize12">The Blockchain Innovation and Analytics Lab envisions a future where the transformative potential of blockchain technology is fully realized. Our commitment lies in advancing the understanding and practical application of blockchain, with key objectives: </span>
          <SwiperHome imgFile={'sw1'} />
          <h1 className="mobile-lineImg mobile-sameTit ">Lab Vision</h1>
          <span className="mobile-txt-2 wsFont fontSize12">The Blockchain Innovation and Analytics Lab envisions a future where the transformative potential of blockchain technology is fully realized. Our commitment lies in advancing the understanding and practical application of blockchain, with key objectives: </span>
          <SwiperHome imgFile={'sw2'} />
        </div>
      </div>
      <div className="mobile-newsEvents " id="mobile-newsEvents">
        <h1 className="mobile-lineImg mobile-sameTit ">News & Events</h1>
        <div className="mobile-swiperBox ">
          <SwiperComp imgList={swiperList1} direction={false} />
          <SwiperComp imgList={swiperList2} direction={true} />
        </div>
      </div>
      <div className="mobile-partBox">
        <div className="mobile-train-teach " id="mobile-train-teach">
          <h1 className="mobile-lineImg mobile-sameTit ">Teaching and Training</h1>
          <SwiperTrain />
        </div>
        <div className="mobile-people" id="mobile-people">
          <h1 className="mobile-lineImg mobile-sameTit ">Faculty</h1>
          <img src={people_1} className="mobile-peopleImg-1 " alt="Faculty" />
          <h1 className="mobile-lineImg mobile-sameTit " >Scientists</h1>
          <img src={group} className="mobile-group-1 " alt="Scientists" />
          <h1 className="mobile-lineImg mobile-sameTit " >Student Researcher</h1>
          <img src={researcher} className="mobile-researcher " />
        </div>
        <div className="mobile-partner " id="mobile-partner">
          <h1 className="mobile-lineImg mobile-sameTit ">Strategic Partner</h1>
          <span className="mobile-partner-url">
            <Partner />
          </span>
        </div>
        <div className="mobile-support ">
          <h1 className="mobile-lineImg mobile-sameTit "  >Community Support</h1>
          <span className="mobile-support-url">
            <Support />
          </span>
        </div>

      </div>
      <footer id="mobile-footerBox" >
      <div className="mobile-footContent">
          <p style={{'color': '#B7B1BE'}}><img src={email}></img>Contact us</p>
          <p>Blockchain Innovation and Analytics Lab @gmail.com</p>
        </div>
        <div className="mobile-urlBox">
          <a ><img src={icon1}></img></a>
          <a ><img src={icon2}></img></a>
          <a ><img src={icon3}></img></a>
          <a ><img src={icon4}></img></a>
          <a ><img src={icon5}></img></a>
        </div>
        
      </footer>

    </div>
  );
}

export default Mobile;
