import React, { useState, useEffect } from 'react';
import PC from './pages/pc/website';
import Mobile from './pages/mobile/phone';

const ScreenSizeListener = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <>{screenWidth > 768 ? <PC /> : <Mobile />}</>

};

export default ScreenSizeListener;
