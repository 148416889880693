import group from '@/assets/pc/group.png';
import nav from '@/assets/pc/nav.png';
import people_1 from '@/assets/pc/people_1.png';
import researcher from '@/assets/pc/researcher.png';
import objectives from '@/assets/pc/objectives.png';
import email from '@/assets/pc/email.png';
import { SwiperComp } from '@/components/pc/swiper';
import $ from 'jquery';
import '@/assets/css/animate.css';
import './website.css';
function PC() {
    var isShowNav = false;
    window.addEventListener('scroll', function (event) {
        event.stopPropagation();
        const scrollTop = document.documentElement.scrollTop;
        if (scrollTop == 0) {
            $('.headerBox ').removeClass('headerFix')
        }
        if (scrollTop > 40) {
            $('.headerBox ').addClass('headerFix')
        }
        if (scrollTop > 600) {
            $('.homeImg-2 ').addClass('fadeIn');
        }
        if (scrollTop > 1500) {
            $('.homeImg-3 .lineImg').addClass('fadeInDown');
            $('.txt-3').addClass('fadeInUp');
        }
        if (scrollTop > 2000) {
            $('.img-5').addClass('fadeInLeft');
            $('.img-6').addClass('fadeInRight');
        }
        if (scrollTop > 2850) {
            $('.newsEvents').addClass('fadeInUp');
        }
        if (scrollTop > 3500) {
            $('.train-teach .lineImg').addClass('fadeIn');
            $('.teaching').addClass('fadeInRight');
            $('.training').addClass('fadeInLeft');
        }
        if (scrollTop > 4700) {
            $('.people .lineImg').addClass('fadeIn');
            $('.peopleImg-1').addClass('fadeInLeft');
        }
        if (scrollTop >5000) {
            $('.scientistsImg').addClass('fadeInLeft');
          
        }
        if (scrollTop > 5920) {
            $('.researcher').addClass('fadeInUp');
        }
        if (scrollTop > 6700) {
            $('.partner .lineImg').addClass('fadeInDown');
            $('.partner_0').addClass('fadeInUp');
            $('.partner_1').addClass('fadeInUp');
            $('.partner_2').addClass('fadeInUp');
        }
        if (scrollTop > 7200) {
            $('.support_0').addClass('fadeInLeft');
            $('.support_1').addClass('fadeInLeft');
            $('.support_2').addClass('fadeInLeft');
            $('.support_3').addClass('fadeInLeft');
            $('.support_4').addClass('fadeInRight');
            $('.support_5').addClass('fadeInRight');
            $('.support_6').addClass('fadeInRight');
            $('.support_7').addClass('fadeInRight');
            $('footer').addClass('fadeInUp');
        }
    })
    
    const swiperList1 = [
        'sw1.png',
        'sw2.png',
        'sw3.png',
        'sw4.png',
        'sw5.png',
    ]
    const swiperList2 = [
        'sw6.png',
        'sw7.png',
        'sw8.png',
        'sw9.png',
        'sw10.png',
    ]
    const partnerUrl = [
        { url: '', name: '' },
        { url: '', name: '' },
        { url: '', name: '' },
    ];
    function Partner() {
        var elements = [];
        partnerUrl.forEach((item, index) => {
            elements.push(<a key={index} href={item.url} className={"partner_" + index + " " + 'animated'}>

            </a>);
        })

        return elements;
    }
    const supportUrl = [
        { url: '', name: '' },
        { url: '', name: '' },
        { url: '', name: '' },
        { url: '', name: '' },
        { url: '', name: '' },
        { url: '', name: '' },
        { url: '', name: '' },
        { url: '', name: '' },
    ];
    function Support() {
        var elements = [];
        supportUrl.forEach((item, index) => {
            elements.push(<a key={index} href={item.url} className={"support_" + index + " " + 'animated'} >
            </a>);
        })

        return elements;
    }
    function scrollToAnchor(anchorName) {
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            const offset = document.querySelector('.headerBox').offsetHeight; // 固定头部的高度
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) {
                anchorElement.scrollIntoView({ block: 'start', behavior: 'instant' });
                $(".newNav").hide();
                isShowNav = false;
                console.log( anchorElement.offsetTop - offset)

                window.scrollTo({
                    top: anchorElement.offsetTop - offset,
                    behavior: 'instant'
                });
            }
        }
    }

    const handleNav = () => {
        if (isShowNav) {
            $(".newNav").hide();
            isShowNav = false
        } else {
            $(".newNav").fadeIn();
            isShowNav = true
        }

    }


    return (
        <div className="app" id="app" >

            <header className="headerBox " >
                <a onClick={() => scrollToAnchor('homeBox')} >Home</a>
                <a onClick={() => scrollToAnchor('newsEvents')}  >News & Events</a>
                <a onClick={() => scrollToAnchor('train-teach')} >Teaching and Training</a>
                <a onClick={() => scrollToAnchor('people')} >People</a>
                <a onClick={() => scrollToAnchor('partner')} >Partners</a>
                <a onClick={() => scrollToAnchor('footerBox')}>Contact Us</a>
            </header>
            <header className="navHeader">
                <img onClick={handleNav} className="navBtn" src={nav} />
                <div className="newNav">
                    <a onClick={() => scrollToAnchor('homeBox')} >Home</a>
                    <a onClick={() => scrollToAnchor('newsEvents')}  >News & Events</a>
                    <a onClick={() => scrollToAnchor('train-teach')} >Teaching and Training</a>
                    <a onClick={() => scrollToAnchor('people')} >People</a>
                    <a onClick={() => scrollToAnchor('partner')} >Partners</a>
                    <a onClick={() => scrollToAnchor('footerBox')}>Contact Us</a>
                </div>
            </header>

            <div className="homeBox maxW1080" id="homeBox">
                <h1 className="tit-1 animated fadeInLeft sgFont">Blockchain Innovation and Analytics Lab</h1>
                <span className="txt-1 wsFont animated fadeInRight">The Blockchain Innovation and Analytics Lab aims to be a hub of excellence in research, training and development, focusing on leveraging technology for sustainable business solutions. The lab, strategically embedded in the research clusters of both Future of Business and Sustainability, school of business, aims to be a pioneering platform for cutting-edge research, development, and education in the rapidly evolving field of business innovation.</span>
                <div className="homeImg-1 animated bounceIn" >
                    {/* <a href="">  <img src={home_2} className="img-0" alt="icon" /></a> */}
                </div>
            </div>

            <div className="homeImg-2 animated">
                <img src={objectives} className="objectives" />
            </div>
            <div className="homeImg-3 maxW1080">
                <h1 className="lineImg sameTit animated">Lab Vision</h1>
                <span className="txt-3 wsFont animated">The Blockchain Innovation and Analytics Lab envisions a future where the transformative potential of blockchain technology is fully realized. Our commitment lies in advancing the understanding and practical application of blockchain, with key objectives: </span>

                <span className='txtBox'>
                    <div className="txt-4 ">
                        <span className="img-5 wsFont animated">
                            <p>Research Projects</p>
                            <p>Engage students at UG, PG, and HDR levels in projects that explore the scalability, security, and interoperability of blockchain.</p>
                        </span>
                        <span className="img-6 wsFont animated">
                            <p>Industry Collaboration</p>
                            <p>Facilitate partnerships with industries, providing students with opportunities to implement real-world blockchain solutions through collaborative projects.</p>
                        </span>
                    </div>
                    <div className="txt-5">
                        <span className="img-6 wsFont animated">
                            <p>Workshops and Training</p>
                            <p>Organize educational sessions, involving both teaching and learning components, to disseminate knowledge about information technology and data-driven decision-making to students across levels.</p>
                        </span>
                        <span className="img-5 wsFont animated">
                            <p>Analytics and Visualization</p>
                            <p> Involve scholars and students in the development of tools for analyzing and visualizing blockchain data, encouraging meaningful insights and hands-on experiences."</p>
                        </span>
                    </div>
                </span>
            </div>
            <div className="newsEvents animated" id="newsEvents">
                <h1 className="lineImg sameTit ">News & Events</h1>
                <div className="swiperBox ">
                    <SwiperComp imgList={swiperList1} direction={false} />
                    <SwiperComp imgList={swiperList2} direction={true} />
                </div>

            </div>
            <div className="part3 maxW1080">
                <div className="train-teach animated" id="train-teach">
                    <h1 className="lineImg sameTit animated">Teaching and Training</h1>
                    <div className="flexBox">
                        <span className="teaching animated">
                            <h1>Teaching Courses</h1>
                            <div className="contentTxt contentTxt1">
                                <p className="title">
                                    Course 1. Blockchain and Dapp Development<br />
                                    <a>(with coding module)</a>
                                </p>
                                <p>
                                    Module 1: Blockchain Introduction<br />
                                    Lecture 1: Decentralizing the World, Diffusion of Web3 Innovation<br />
                                    Lecture 2: Blockchain 101<br />
                                    Lecture 3: Bridge Web2 and Web3, Metaverse and Middlewares
                                </p>
                                <p>
                                    Module 2: Smart Contract and Dapp design<br />
                                    Lecture 1: Smart Contract 101 – Build your first smart contract<br />
                                    Lecture 2: AI Driven Full Stack Development<br />
                                    Lecture 3: Fundamental Steps of Building a Dapp
                                </p>
                                <p>
                                    Module 3: Developing a Blockchain Game<br />
                                    Lecture 1: Blockchain Game Designer 1<br />
                                    Lecture 2: Blockchain Game Designer 2<br />
                                    Lecture 3: Blockchain Game Designer 3
                                </p>
                                <p>
                                    Module 4: Blockchain, Multimedia and Data Science<br />
                                    Lecture 1: BMDS Applications I: Where Blockchain Meets Interactive Multimedia<br />
                                    Lecture 2: Blockchain & AI Blockchain -empowered Privacy-Preserving Machine Learning and the Trusted Computing<br />
                                    Lecture 3: Master of Blockchain: Hands-on Experience for Blockchain
                                </p>
                                <p>Module 5: Group Project</p>
                                <p className="title">
                                    Course 2. Web 3.0 Eco-system and Analytics <br />
                                    <a>(No-coding skills required)</a>
                                </p>
                                <p>
                                    Module 1: Blockchain Introduction<br />
                                    Lecture 1: Decentralizing the World, Diffusion of Web3 Innovation<br />
                                    Lecture 2: Blockchain 101<br />
                                    Lecture 3: Bridge Web2 and Web3, Metaverse and Middlewares
                                </p>
                                <p>
                                    Module 2: Blockchain and Metaverse<br />
                                    Lecture 1: From Blockchain to Metaverse<br />
                                    Lecture 2: Metaverse for Social Good: Thoughts from University Perspective<br />
                                    Lecture 3: Metaverse ecosystem and community construction
                                </p>
                                <p>
                                    Module 3: Crypto Art and NFT<br />
                                    Lecture 1: How Crypto Changes Art and What Creators Can Learn from it？<br />
                                    Lecture 2: Entering the Metaverse: Why NFTs are important?<br />
                                    Lecture 3: NFT and creator economy
                                </p>
                                <p>
                                    Module 4: Token Economy<br />
                                    Lecture 1: How Blockchains and Smart Contracts Revolutionize the Economy<br />
                                    Lecture 2: Opportunities of Defi and NFT in Metaverse<br />
                                    Lecture 3: GamingFi: Building a sustainable gaming economy
                                </p>
                                <p>
                                    Module 5: Blockchain game ecosystem<br />
                                    Lecture 1: How Blockchain Gaming is Taking the Crypto Ecosystem to the Next Level<br />
                                    Lecture 2: Start a Business in Blockchain
                                </p>
                                <p>
                                    Team Project: Analytics on a web3 project
                                </p>
                            </div>
                        </span>
                        <span className="training animated">
                            <h1>Industrial Training</h1>
                            <div className="contentTxt ">

                                <p> Welcome to our Industrial Training Program, where we will explore cutting-edge technologies and business opportunities in the UAE. </p>
                                <p>This comprehensive training program will cover a range of topics, including Blockchain innovation, Web 3.0, Cloud Computing, Big data, Artificial Intelligence, Data Analytics, FinTech, as well as insights into the UAE business environment and opportunities, including Free Zone policy.</p>
                                <p>  Throughout this training program, participants will have the opportunity to gain practical skills and knowledge in these key areas, enabling them to stay ahead in today's rapidly evolving industrial landscape. Our expert trainers will provide in-depth insights and hands-on experience, equipping participants with the tools and expertise needed to thrive in the dynamic UAE business environment.</p>
                                <p>   Join us as we delve into the exciting world of technology and business in the UAE, and embark on a journey of discovery and innovation.</p>
                                <p>   Let's shape the future together!</p>
                            </div>
                        </span>
                    </div>
                </div>
                <div className="people" id="people">
                    <h1 className="lineImg sameTit animated">Faculty</h1>
                    <img src={people_1} className="peopleImg-1 animated" alt="Faculty" />
                    <h1 className="lineImg sameTit animated scientists">Scientists</h1>
                    <img src={group} className="scientistsImg animated" alt="icon" />
                    {/* <ul className="scientistsImg ">
                        <Scientists />
                    </ul> */}
                    <h1 className="lineImg sameTit animated student" >Student Researcher</h1>
                    <img src={researcher} className="researcher animated" alt="icon" />
                </div>
                <div className="partner " id="partner">
                    <h1 className="lineImg sameTit animated">Strategic Partner</h1>
                    <span className="partner-url">
                        <Partner />
                    </span>
                    <h1 className="lineImg sameTit animated community"  >Community Support</h1>
                    <span className="support-url">
                        <Support />
                    </span>
                </div>
            </div>
            <footer id="footerBox" className="animated">
                <div className="urlBox">
                    <a href=""></a>
                    <a href=""></a>
                    <a href=""></a>
                    <a href=""></a>
                    <a href=""></a>
                </div>
                <div className="footContent">
                    <p>Contact us</p>
                    <p><img src={email}></img>Blockchain Innovation and Analytics Lab @gmail.com</p>
                </div>
            </footer>
        </div>


    );
}

export default PC;
